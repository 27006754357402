.App {
  margin: 0;
  justify-content: center;
  align-content: center;
}

/* @media screen and (max-width:2000px) {
  .App { font-size: 4.25vw; }
} */

body {
  background-color: ghostwhite;
}