.Lorenz {
    width: 100vw;
    max-width: 2000px;
    margin: auto;
    background-color: black;
}

#header {
    color: white;
    text-align: center;
    font-size: 60px;
    padding-top: 10px;
    padding-bottom: 100px;
    width: 100vw;
    max-width: 2000px;
    margin: auto;
    background-color: black;
}

@media screen and (max-width:990px) {
    #header { font-size: 8vw }
}

.grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 5vh;
    width: 90vw;
    max-width: 1800px;
    margin: auto;
    background-color: black;
}

@media screen and (max-width:1440px) {
    .grid { grid-template-columns: repeat(2, 1fr); }
}

@media screen and (max-width:990px) {
    .grid { grid-template-columns: repeat(1, 1fr); }
}

.cell {
    text-align: center;
    background-color: black;
}

.cellImg {
    width: 26vw;
    max-width: 500px;
    max-height: 500px;
}

#groupOne {
    height: 26vw;
}
#groupTwo {
    height: 26vw;
}
#groupThree {
    height: 26vw;
}

@media screen and (max-width:1440px) {
    .cellImg { width: 40vw; }
    #groupOne { height: 40vw; }
    #groupTwo { height: 40vw; }
    #groupThree { height: 0; }
}

@media screen and (max-width:990px) {
    .cellImg { width: 80vw; }
    #groupOne { height: 80vw; }
    #groupTwo { height: 0; }
    #groupThree { height: 0; }
}

/* ------------------------------- */

.lorenz-content-container {
    background-color: ghostwhite;
    color: black;
    width: 100%;
}

.content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: 1 / -1;
    order: -1;
    height: 50vw;
    max-height: 1000px;
    justify-items: center;
    align-items: center;
}

@media screen and (max-width:990px) {
    .content { grid-template-columns: repeat(1, 1fr); height: auto; max-height: 2000px; width: 80vw; margin-left: 10vw;}
}
/* For scroll */

.contentM {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-height: 1000px;
    height: 50vw;
    justify-items: center;
    align-items: center;
}

@media screen and (max-width:990px) {
    .contentM { grid-template-columns: repeat(1, 1fr); height: 100vw; max-height: 2000px; width: 80vw; padding-top: 10vh;
        padding-bottom: 10vh;}
}

.contentMM {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-height: 1000px;
    height: 50vw;
    justify-items: center;
    align-items: center;
}

@media screen and (max-width:990px) {
    .contentMM { grid-template-columns: repeat(1, 1fr); height: 100vw; max-height: 2000px; width: 80vw; padding-top: 10vh;
        padding-bottom: 10vh;}
}

.contentText {
    align-items: center;
}

@media screen and (max-width:990px) {
    .contentText { order: -1 }
}

.contentTitle {

}

.contentPara {
    width: 33vw;
    max-width: 900px;
}

@media screen and (max-width:990px) {
    .contentPara { width: 80vw; }
}

.content-image-containter {
    background-color: black;
}

.contentImg {
    height: 50vw;
    width: 50vw;
    max-width: 1000px;
    max-height: 1000px;
}

@media screen and (max-width:990px) {
    .contentImg { width: 80vw; height: 80vw }
}

/* ------------------------------- */

.animateImg {
    height: 50vw;
    width: 50vw;
    max-width: 1000px;
    max-height: 1000px;
}

@media screen and (max-width:990px) {
    .animateImg {width: 80vw; height: 80vw}
}

/* ------------------------------- */

#invert {
    color: white;
    background-color: black;
    text-align: center;
}

#mint {
    color: white;
    height: 100%;
    width: 100%;
    margin-top: -150px;
    background-color: black;
    text-align: left;
}

#animate {
    color: white;
    height: 100%;
    width: 100%;
    background-color: black;
    text-align: left;
}

#revert {
    color: black;
    background-color: white;
}

.lorenz-collection-image {
    height: 50vw;
    width: 50vw;
    max-width: 1000px;
    max-height: 1000px;
}

@media screen and (max-width:990px) {
    .lorenz-collection-image {width: 80vw; height: 80vw}
}