.Home {
    width: 100vw;
    max-width: 2000px;
    margin: auto;
}

.center {
    text-align: center;
}

.button {
    border: 1px solid white;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 1.2rem;
    text-decoration: none;
}

.button:hover {
    border: 1px solid white;
    background-color: white;
    color: black;
}

.button-disabled {
    border: 1px solid white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 1.2rem;
    text-decoration: none;
}

.ex-intro {
    width: 100vw;
    margin: auto;
    background-color: black;
}

.intro {
    background-color: black;
    height: 100vh;
    min-height: 1000px;
    max-height: 1500px;
    width: 100vw;
    color: white;
    justify-content: center;
}

@media screen and (max-width:990px) {
    .intro { height: 160vh;}
}

.intro-container {
    display: flex;
    justify-content: center;
    padding-top: 10vh;
    align-items: center;
}

@media screen and (max-width:990px) {
    .intro-container { flex-wrap: wrap; }
}

.introText {
    font-size: 1.5rem;
    padding-top: 0px;
}

.introPara {
    font-size: 1.5rem;
    width: 600px;
}

@media screen and (max-width:990px) {
    .introPara { width: 80vw; }
}

.introImg {
    height: 35vw;
    width: 35vw;
}

@media screen and (max-width:990px) {
    .introImg { height: 80vw; width: 80vw; }
}

@media screen and (min-width:1440px) {
    .introImg { height: 500px; width: 500px; }
}

.introScroll {
    padding-top: 2vh;
    height: 90px;
    width: 100px;
    content:url("../assets/down-arrow.png");
}

.introScroll:hover {
    content:url("../assets/down-arrow-hover.png");
}

/*-----------*/

.fourth {
    background-color: ghostwhite;
    color: black;
    display: flex;
    justify-content: center;
}

@media screen and (max-width:990px) {
    .fourth { flex-wrap: wrap; }
}

.fourthText {
    padding-top: 15px;
    padding-bottom: 15px;
}

.fourthPara {
    width: 650px;
}

@media screen and (max-width:990px) {
    .fourthPara { width: 80vw; }
}

.fourthImg {
    height: 500px;
    width: 500px;
}



/* ------------------------------- */

.collection-container {
    background-color: black;
    color: white;
    padding-left: 5vw;
    padding-right: 5vw;

}

/* ------------------------------- */

.lorenz {
    display: flex;
    justify-content: center;
}

@media screen and (max-width:990px) {
    .lorenz { flex-wrap: wrap; }
}

.lorenzText {
    padding-top: 25px;
    padding-bottom: 25px;
}

.lorenzPara {
    width: 600px;
}

@media screen and (max-width:990px) {
    .lorenzPara { width: 80vw; }
}

.lorenzImg {
    height: 350px;
    width: 350px;
}

@media screen and (max-width:990px) {
    .lorenzImg { height: 80vw; width: 80vw; }
}