.footer {
    background-color: black;
    padding-top: 15px;
    padding-bottom: 15px;
    color: white;
}

.linkContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.con {
    display: flex;
    justify-content: center;
}

#footerButton a
{
    text-decoration: none;
    font-size: 1.2rem;
    color: white;
}