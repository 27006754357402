.nav {
    background-color: black;
    width: 100%;
    max-width: 2000px;
    margin: auto;
    color: white;
    display: flex;
    justify-content: space-between;
}

#navButton a
{
    text-decoration: none;
    padding-left: 12.5px;
    padding-right: 12.5px;
    padding-bottom: 5px;
    font-size: 1.2rem;
    color: white;
}

.logo {
    height: 100px;
    width: 100px;
    padding-left: 15px;
    padding-top: 15px;
}

.mint {
    border: 1px solid white;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 15px;
    margin-right: 30px;
    height: 30px;
    font-size: 1.2rem;
    text-decoration: none;
}

.mint a:visited {
    text-decoration: none;
    color: white;
}

.mint:hover {
    border: 1px solid white;
    background-color: white;
    color: black;
}

.left {

}

.right {
    padding-top: 15px;
    display: flex;
}